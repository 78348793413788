import React, { Suspense, lazy } from 'react'
import { Routes, Route } from 'react-router-dom'
import Loader from 'views/pages/Loader'

const PlainLayouts = lazy(() => import('views/layouts/PlainLayouts'))
const WebLayouts = lazy(() => import('views/layouts/WebLayouts'))
const CollectorLayouts = lazy(() => import('views/layouts/CollectorLayouts'))
const PlainLayoutsPassbook = lazy(() => import('views/layouts/plainLayoutPassbook'))
const PlainLayoutsPassbookCollector = lazy(() => import('views/layouts/plainLayoutCollector'))

const NotFound = lazy(() => import('views/pages/404'))
const Accounts = lazy(() => import('views/pages/accounts'))
const Chits = lazy(() => import('views/pages/chits/list'))
const ChitInfo = lazy(() => import('views/pages/chits/info'))
const Collections = lazy(() => import('views/pages/collections'))
const CollectionForm = lazy(() => import('views/pages/collections/form/create'))
const Dashboard = lazy(() => import('views/pages/dashboard'))
const CollectorDashboard = lazy(() => import('views/pages/CollectorDashboard'));
const ErrorPage = lazy(() => import('views/pages/error'))
const Home = lazy(() => import('views/pages/home'))
const Profile = lazy(() => import('views/pages/profile'))
const Login = lazy(() => import('views/pages/login'))
const Members = lazy(() => import('views/pages/members'))
const MembersView = lazy(() => import('views/pages/members/view'))
const Profits = lazy(() => import('views/pages/profit'))
const SinglePassbookView = lazy(() => import('views/pages/passbook/SingleView'))
const PassbookListView = lazy(() => import('views/pages/passbook/AllList'))

const PassbookListViewClosed = lazy(() => import('views/pages/passbook/AllList/closed'))

const UnAuthenticate = lazy(() => import('views/pages/un-authenticate'))

function App() {
    return (
        <Suspense fallback={<Loader />}>
            <Routes>
                <Route element={<PlainLayouts />}>
                    <Route exact path='/' element={<Login />}></Route>
                    <Route path='/login' element={<Login />}></Route>
                    <Route path='/un-authenticate' element={<UnAuthenticate />}></Route>
                </Route>

                <Route path="/admin" element={<PlainLayoutsPassbook />}>
                    <Route path='/admin/passbook/:chitID' element={<PassbookListView />}></Route>
                    <Route path='/admin/closed/:chitID' element={<PassbookListViewClosed />}></Route>
                </Route>

                <Route path="/collector" element={<PlainLayoutsPassbookCollector />}>
                    <Route path='/collector/passbook/:chitID' element={<PassbookListView />}></Route>
                    <Route path='/collector/closed/:chitID' element={<PassbookListViewClosed />}></Route>
                </Route>

                <Route path="/admin" element={<WebLayouts />}>
                    <Route path='/admin/home' element={<Home />}></Route>
                    <Route path='/admin/not-found' element={<NotFound />}></Route>
                    <Route path='/admin/accounts' element={<Accounts />}></Route>
                    <Route path='/admin/chits' element={<Chits />}></Route>
                    <Route path='/admin/chits-info/:chitType' element={<ChitInfo />}></Route>
                    <Route path='/admin/collections' element={<Collections />}></Route>
                    <Route path='/admin/add-collections' element={<CollectionForm />}></Route>
                    <Route path='/admin/dashboard' element={<Dashboard />}></Route>
                    <Route path='/admin/error' element={<ErrorPage />}></Route>
                    <Route path='/admin/members' element={<Members />}></Route>
                    <Route path='/admin/profile' element={<Profile />}></Route>
                    <Route path='/admin/profits' element={<Profits />}></Route>
                    <Route path='/admin/memberview/:memberID' element={<MembersView />}></Route>
                    <Route path='/admin/singlePassbook/:ID' element={<SinglePassbookView />}></Route>
                </Route>
                <Route path='/collector' element={<CollectorLayouts />}>
                    <Route path='/collector/dashboard' element={<CollectorDashboard />}></Route>
                    <Route path='/collector/chits' element={<Chits />}></Route>
                    <Route path='/collector/chits-info/:chitType' element={<ChitInfo />}></Route>
                    <Route path='/collector/members' element={<Members />}></Route>
                    <Route path='/collector/memberview/:memberID' element={<MembersView />}></Route>
                    <Route path='/collector/collections' element={<Collections />}></Route>
                    <Route path='/collector/singlePassbook/:ID' element={<SinglePassbookView />}></Route>
                    {/* <Route path='/collector/passbook/:chitID' element={<PassbookListView />}></Route> */}
                    <Route path='/collector/profile' element={<UnAuthenticate />}></Route>
                </Route>
                <Route path='*' element={<NotFound />} />
            </Routes>
        </Suspense>
    );
}

export default App;