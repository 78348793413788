import React from 'react'
import Logo from '../../../assets/logo.png'
import CircularProgress from '@mui/material/CircularProgress'
import Box from '@mui/material/Box'


const Loader = () => {
    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                justifyContent: 'center',
                height: '80vh'
            }}
        >
            <img src={Logo} alt="Logo" width={120} />
            <CircularProgress disableShrink sx={{ mt: 6 }} />
        </Box>
    )
}

export default Loader
